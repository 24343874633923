:root {
    --main-theme-color: #ec0101;
    --heading-color: #283878;
    --paragraph-color: #5f6a75;
}

/*text color*/
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}